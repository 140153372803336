
import { Bar } from 'vue-chartjs'

import { stringifyDate } from '@/utils'

export default {
  extends: Bar,
  props: {
    name: {
      type: String,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      chartData: {
        labels: [],
        datasets: [{
          label: '',
          borderWidth: 1,
          borderColor: '#295072',
          pointBorderColor: '#295072',
          backgroundColor: '#295072',
          data: [],
        }],
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false,
              suggestedMin: 0,
              suggestedMax: 0,
              precision: 0,
            },
            gridLines: {
              display: true,
            },
          }],
          xAxes: [{
            type: 'time',
            gridLines: {
              display: false,
            },
            display: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 12,
              maxRotation: 0,
            },
          }],
        },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem) {
              return stringifyDate(new Date(tooltipItem[0].xLabel))
            },
            label: function (item) {
              return item.value
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },
  mounted () {
    this.generateChartData()
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    generateChartData () {
      this.chartData.labels = this.labels
      this.chartData.datasets[0].data = this.values
      this.chartData.datasets[0].label = this.name
      this.options.scales.yAxes[0].ticks.suggestedMin = Math.min(...this.values) - 1
      this.options.scales.yAxes[0].ticks.suggestedMax = Math.max(...this.values) + 1
    },
  },
}
